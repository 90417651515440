/** Montserrat */
@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Medium.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-SemiBold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Bold.ttf);
    font-weight: bold;
}

/** SFPro */
@font-face {
    font-family: 'SFPro';
    src: url(../fonts/SFPro-Regular.ttf);
}

@font-face {
    font-family: 'SFPro';
    src: url(../fonts/SFPro-Medium.ttf);
}

@font-face {
    font-family: 'SFPro';
    src: url(../fonts/SFPro-Semibold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: 'SFPro';
    src: url(../fonts/SFPro-Bold.ttf);
    font-weight: bold;
}

/*--------------- fonts ----------------*/
@font-face {
    font-family: 'GillSans';
    src: url('../fonts/GillSans.otf') format('opentype');
    font-weight: 500;
  }
  @font-face {
    font-family: 'GillSans';
    src: url('../fonts/GillSansMedium.otf') format('opentype');
    font-weight: 600;
  }
  /* @font-face {
    font-family: 'GillSansSemiBold';
    src: url('../fonts/GillSansSemiBold.ttf') format('truetype');
  } */
  @font-face {
    font-family: 'GillSans';
    src: url('../fonts/GillSansBold.otf') format('opentype');
    font-weight: 700;
  }
  