/* Start with the largest breakpoint and move downwards */

@media (max-width: 1600px) {}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {
    .about_section_eyw_line {
        bottom: -30px;
    }

    .about_mobileview {
        text-align: center;
    }

}

@media (max-width: 1199px) {}

@media (max-width: 1024px) {
    :root {
        --containerPaddingSide: 30px;
    }

    body {
        padding-top: unset;
    }

    .container {
        padding: 0 var(--containerPaddingSide);
    }

    .header_logo {
        padding: 15px 0;
    }

    .header_right_box_item {
        padding: 0 var(--containerPaddingSide);
    }

    .footer_inner {
        padding: 30px var(--containerPaddingSide);
    }

    .mobile_header {
        right: var(--containerPaddingSide);
    }
}

@media (max-width: 991px) {}

@media (max-width: 768px) {
    body {
        font-size: 14px;
        overflow-x: hidden;
    }

    h1 {
        font-family: 'SFPro';
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
        margin: unset;
    }

    h2,
    .custom_button_text {
        font-family: 'SFPro';
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin: unset;
    }

    h3 {
        font-family: 'SFPro';
        font-weight: 100;
        font-size: 10px;
        line-height: 24px;
        margin: unset;
    }

    p {
        font-family: 'SFPro';
        font-weight: normal;
        font-size: 10px;
        line-height: 24px;
        margin: unset;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        background-color: #ffffff;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
    }

    .home_banner_background_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        padding-top: clamp(350px, 49.8958%, 958px);
        overflow: visible;
        overflow-x: clip;
    }

    .home_banner_content_left_width {
        width: 100%;
        margin-right: -35px;
        order: 1;
        /* Place it below */
        margin-top: 5%;
    }

    .home_banner_content_left_width h1 {
        margin-bottom: -15px;
        white-space: nowrap;
        overflow: visible;
    }

    .home_banner_content_left_width h2 {
        margin-bottom: -40px;
    }

    .home_banner_content_left_width .custom_button {
        width: 147px;
        position: relative;
        margin-top: 20px;
        background: transparent;
        border: unset;
    }

    .home_banner_content_left_width .custom_button_border {
        border: 3px solid white;
        padding: 10px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        left: -110px;
        position: relative;
        cursor: pointer;
        z-index: 2;
    }

    .home_banner_content_left_width .custom_button_background_position {
        position: absolute;
        top: 2.5px;
        left: -10px;
        width: calc(100% - 15px);
        height: calc(100% - 5px);
        border-radius: 100px;
        z-index: 1;
    }

    .home_banner_content_left_width .custom_button_text {
        line-height: unset;
    }

    .home_banner_content_right_width {
        transform: translateX(50px) translateY(120px);
        width: 100%;
        /* Take full width on mobile */
        max-width: none;
        /* Reset max-width */
        order: 2;
        /* Place it on top */
    }

    .home_section_oc_title_inner {
        display: flex;
        justify-content: center;
        margin: 70px 0;
    }

    .home_section_os_title_inner {
        display: flex;
        justify-content: center;
        margin: 50px 0;
        margin-bottom: 30px;
        font-size: 27px;
    }

    /* Adjust the container of the ourServices section to use flexbox */
    .home_section_os_list {
        display: flex;
        flex-direction: column;
        /* Stack items vertically */
        align-items: flex-start;
        /* Center items horizontally */
        margin-bottom: -20px;
    }

    .home_os_mb {
        display: flex;
        align-items: center;
        /* This will vertically center the image and content */
        margin-bottom: 50px;
    }

    .home_os_mb_content {
        margin-left: 10px;
        /* Adjust as needed for spacing between image and content */
        width: 100%;
    }

    .mt_10 .custom_button {
        position: relative;
        margin-top: -50px;
        background: transparent;
        border: unset;
        margin-left: 0;
    }

    .home_section_eyw_overflow {
        padding-bottom: 150px;
        overflow: hidden;
        margin-top: -40px;
    }

    .home_section_eyw {
        flex-direction: column;
        border-radius: 20px;
        box-shadow: none;
        padding: 30px 30px 50px;
    }

    .home_section_eyw_left {
        width: 100%;
        /* Make them take the full width on mobile */
    }

    .home_section_eyw_right {
        width: 100%;
        /* Make them take the full width on mobile */
        margin-left: 0;
        margin-top: -20px;
    }

    .home_section_eyw_left h1 {
        text-align: center;
    }

    .home_section_eyw_left h2 {
        text-align: center;
        margin-bottom: 30px;
    }

    .home_section_gd_overflow {
        margin-top: -80px;
        margin-bottom: -100px;
    }

    .home_section_gd h1 {
        text-align: center;
    }

    .home_section_gd h2 {
        text-align: center;
        margin-bottom: 30px;
        width: 100%;
    }

    .home_section_gd_list {
        flex-direction: column;
        margin-top: -20px;
        margin-bottom: -50px;
    }

    .home_section_gd_item {
        height: 120px;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-right: none;
        /* Remove the vertical border */
        border-top: 5px solid var(--secondaryColor);
        /* Add horizontal border */
        padding: 20px 0;
        /* Adjust padding for vertical spacing */
    }

    .home_section_gd_item:first-child {
        border-top: none;
        /* Remove the horizontal border for the last item */
    }

    .home_section_gd_item .custom_text_1 {
        font-family: 'SFPro';
        font-weight: bold;
        font-size: 20px;
        line-height: 120px;
        font-size: 35px;
        width: 57%;
    }

    .home_section_gd_item .custom_text_2 {
        font-family: 'SFPro';
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
    }

    .services-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .service-item {
        width: 80%;
        margin-bottom: 20px;
    }

    .service-item img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .service-item .description {
        font-size: 14px;
    }

    .footer {
        padding: 10px;
        background-color: #02011F;
        width: 100%;
        z-index: 1000;
    }

    .footer_row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .footer_row_inner {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .footer_left_text,
    .footer_right_text {
        text-align: center;
        font-size: 15px;
    }

    .footer_icon_list,
    .mobile_footer_icon_list {
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .icon_round {
        margin: 0 5px;
    }

    /* ContactForm styles */
    .custom_input,
    .custom_textarea {
        padding: 10px 15px;
        font-size: 16px;
        font-weight: bold;
        width: 360px;
    }

    /* Button */
    .custom_button {
        width: 45%;
        position: relative;
        margin-top: 10px;
        background: transparent;
        border: none;
    }

    .custom_button_border {
        border: 3px solid;
        margin-top: 10px;
        margin-bottom: -3px;
        margin-left: 98px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        cursor: pointer;
        z-index: 2;
    }

    .custom_button_background_position {
        position: absolute;
        top: 2.5px;
        left: 100px;
        width: calc(100% - 15px);
        height: calc(100% - 5px);
        border-radius: 100px;
        z-index: 1;
    }

    .custom_button_text {
        color: white;
        font-size: 14px;
        padding-left: 10px;
        padding-top: 5px;
    }

    .about_banner_background_color {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .about_banner_background_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        padding-top: clamp(350px, 49.8958%, 958px);
    }

    .container.about_banner_content_position {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .about_section {
        margin-top: -65px;
    }

    .about_section_list {
        margin-top: 25px;
        justify-content: center;
    }

    .about_section_list>* {
        width: 33.33%;
        box-sizing: border-box;
        padding: 0 10px;
    }

    .about_banner_content_left_width {
        width: 90%;
        margin-right: -40px;
        order: 2;
        /* Place it below */
        margin-top: 10px;
    }

    .about_banner_content_right_width {
        padding-top: 50px;
        width: 100%;
        /* Take full width on mobile */
        max-width: none;
        /* Reset max-width */
        order: 1;
        /* Place it on top */
        margin-bottom: 60px;
    }

    .about_mobileview {
        margin-top: -65px;
        text-align: center;
    }

    .service_banner_content {
        padding-top: 70px;
        padding-bottom: 70px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .service_banner_content_left_width {
        width: 60%;
        margin-right: 225px;
        text-align: left;
        order: 3;
    }

    .service_banner_content_right_width {
        width: 100%;
        order: 1;
        color: #fff;
        justify-content: center;
        margin-bottom: -15px;
        align-items: center;
    }

    .service_banner_content_right_width h1 {
        padding-top: 50px;
        order: 1;
        text-align: center;
    }

    .service_banner_content_right_width h2 {
        line-height: 1.5px;
        order: 2;
        font-size: 16px;
        font-weight: 500;
        transform: translateX(-15px);
    }

    .service_section_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: -30px;
        /* 2 columns by default */
        width: 100%;
        box-sizing: border-box;
    }

    .service_section_list p {
        max-width: 90%;
        margin: 0 auto;
        padding: 5px;
        display: grid;
        place-items: center;
        text-align: center;
    }

    .hover_change {
        width: 80px;
        height: 80px;
        border: 5px solid var(--secondaryColor);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        z-index: 2;
    }

    .hover_change_component {
        height: auto;
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .hover_change_background_color {
        background-color: var(--fourthColor);
        position: absolute;
        top: -2%;
        left: -4%;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        width: 95%;
        height: 95%;
        z-index: 1;
    }

    .service_section_contact_us {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .service_section_contact_us_left {
        margin-top: 50px;
        order: 2;
        width: 80%;
        align-items: center;
    }

    .service_section_contact_us_right {
        width: 100%;
        position: relative;
    }

    .service_section_contact_us_text_wrapper {
        order: 1;
        /* Text will be above the image */
    }

    .service_section_contact_us .secondary_color {
        text-align: center;
    }

    .service_section_contact_us h1 {
        order: 1;
        margin-bottom: 20px;
    }

    .hover_change_title {
        height: 50%;
        width: 100%;
        text-align: center;
        margin-top: 18px;
    }

    .serviceContent {
        height: 100%;
    }

    .portfolio_banner_background_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        padding-top: clamp(350px, 49.8958%, 958px);
        overflow: visible;
        overflow-x: clip;
    }

    .container.portfolio_banner_content_position {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .portfolio_banner_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 10px;
        padding-top: 10px;
    }

    .portfolio_banner_content_left_width {
        flex: 1;
        margin-right: 20px;
        /* Adjust as needed */
    }

    .portfolio_banner_content_left_width h1 {
        margin-top: 10px;
    }

    .portfolio_banner_content_right_width {
        flex: 0.8;
    }

    .portfolio_banner_content_right_img {
        transform: translateX(80%) translateY(10%);
        width: 100%;
    }

    .portfolio_section_list {
        grid-template-columns: auto;
        gap: 20px;
    }

    .contact_banner_content_left_width {
        width: 60%;
        margin-right: 0;
        text-align: center;
        order: 3;
    }

    .contact_banner_content_right_width {
        width: 100%;
        order: 1;
        margin-bottom: 40px;
        color: #fff;
    }

    .contact_banner_content_right_width h1 {
        padding-top: 10px;
        order: 1;
    }

    .contact_banner_content_right_width h2 {
        order: 2;
        font-size: 16px;
        font-weight: 500;
    }

    .contact_section {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items:center;
        margin: 15px 0;
    }
    .contact_section_left_container{
        width: 100%;
        margin-right: 0px;
        margin-top: 30px;
    }
    .about_section_eyw_line {
        position: absolute;
        bottom: 0;
        left: -28.6363%;
        right: -28.6363%;
        /* top: 0; */
        z-index: 1;
        transform: translateY(-40.5%) translateX(-3.5%);
    }

    .about_section_eyw_line1 {
        position: absolute;
        bottom: 0;
        left: -28.6363%;
        right: -28.6363%;
        /* top: 0; */
        z-index: 1;
        transform: translateY(20.5%) translateX(-3.5%);
    }


}

@media (max-width: 671px) {

    body {
        font-size: 14px;
        overflow-x: hidden;
    }

    .footer_row {
        flex-direction: column;
    }

    .footer_row:last-child {
        align-items: center;
        margin-top: 0px;
    }

    .footer_row_inner_first_icon {
        width: unset;
        margin-right: 10px;
    }

    .footer_row_inner {
        justify-content: center;
        text-align: center;
    }

    .footer_icon_list {
        display: none;
    }

    .footer_right_text {
        text-align: center;
    }

    .mobile_footer_icon_list {
        display: flex;
        justify-content: space-around;
        width: 200px;
        margin: 30px 0px;
    }



}

@media (max-width: 575px) {
    /* ... styles ... */
}

@media (max-width: 374px) {
    :root {
        --containerPaddingSide: 15px;
    }
}