
:root{
    --primaryColor: #f7f7f7;
    --secondaryColor: #0B093B;
    --thirdColor: #0B4ECA;
    --fourthColor: #FEC836;
    --footerBackgroundColor: #02011F;
    --bannerBackgroundColorStart: #030511;
    --bannerBackgroundColorEnd: #000132;
    --inputBackgroundColor: #efefef;

    --maxContainer: 1920px;
    --containerPaddingSide: 50px;
}

*, ::after, ::before {
    box-sizing: border-box;
}

html {
    font-size: 100%;
    scroll-behavior: smooth;
}

body {
    margin: unset;
    padding-top: 72px;
}

/* Font */
h1{
    /* font-family: 'Gill Sans'; */
    font-family: 'SFPro';
    font-size: 26px;
    font-weight: bold;
    line-height: 40px;
    margin: unset;
}
h2, .custom_button_text{
    /* font-family: 'Gill Sans'; */
    font-family: 'SFPro';
    /* font-weight: 600;
    font-size: 36px;
    line-height: 48px; */
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    margin: unset;
}
h3{
    /* font-family: 'Gill Sans'; */
    font-family: 'SFPro';
    font-weight: 100;
    /* font-size: 24px;
    line-height: 32px; */
    font-size: 14px;
    line-height: 24px;
    margin: unset;
}
p{
    /* font-family: 'Gill Sans'; */
    font-family: 'SFPro';
    /* font-weight: 600;
    font-size: 20px;
    line-height: 27px; */
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    margin: unset;
}

.header_menu_text{
    /* font-family: 'Gill Sans'; */
    font-family: 'SFPro';
    /* font-weight: 700;
    font-size: 20px;
    line-height: 27px; */
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
}

.footer_left_text{
    /* font-family: 'Gill Sans'; */
    font-family: 'SFPro';
    /* font-weight: 600;
    font-size: 35px;
    line-height: 50px; */
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}
.footer_right_text{
    /* font-family: 'Gill Sans'; */
    font-family: 'SFPro';
    /* font-weight: 600;
    font-size: 30px;
    line-height: 50px; */
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.custom_text_1{
    /* font-family: 'Gill Sans'; */
    font-family: 'SFPro';
    font-weight: 700; /* Bold */
    /* font-size: 140px;
    line-height: 186px; */
    font-size: 90px;
    line-height: 120px;
}
.custom_text_2{
    /* font-family: 'Gill Sans'; */
    font-family: 'SFPro';
    font-weight: 400; /* semiBold */
    /* font-size: 47px;
    line-height: 63px; */
    font-size: 26px;
    line-height: 36px;
    text-align: center;
}
.hover_text_1{
    /* font-family: 'Gill Sans'; */
    font-family: 'SFPro';
    font-weight: 600; /* semiBold */
    /* font-size: 16px;
    line-height: 21px;  */
    font-size: 14px;
    line-height: 20px;
}
.small_text_1{
    /* font-family: 'Gill Sans'; */
    font-family: 'SFPro';
    font-weight: 300; /* semiBold */
    /* font-size: 16px;
    line-height: 21px;  */
    font-size: 14px;
    line-height: 20px;
}

/* Input Textarea */
.custom_textarea, .custom_input{
    border-radius: 20px;
    background-color: var(--inputBackgroundColor);
    border-color: var(--inputBackgroundColor);
    padding: 25px 30px 20px;
    color: var(--secondaryColor);
    font-size: 14px;
    font-family: 'SFPro';
    font-weight: 400;
    line-height: 20px;

}
.custom_textarea::placeholder, .custom_input::placeholder{
    color: var(--secondaryColor);
}


/* Color */
.color_primary{
    color: var(--primaryColor)
}
.color_secondary{
    color: var(--secondaryColor)
}
.color_fourth{
    color: var(--fourthColor)
}

.color_primary_hover_fourth:hover .color_primary{
    color: var(--fourthColor)
}

/* container */
.max_container{
    max-width: var(--maxContainer);
}

.container{
    max-width: 1650px;
    width: 100%;
    padding: 0 var(--containerPaddingSide);
    margin: 0 auto;
}

/* Button */
.custom_button{
    width: 210px;
    position: relative;
    margin-top: 30px;
    background: transparent;
    border: unset;
}
.custom_button_border{
    border: 5px solid white;
    padding: 10px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    cursor: pointer;
    z-index: 2;
}
.custom_button_background_position{
    position: absolute;
    top: 2.5px;
    left: 6px;
    width: calc(100% - 15px);
    height: calc(100% - 5px);
    border-radius: 100px;
    z-index: 1;
}
.custom_button_text{
    line-height: unset;
}
/* .custom_button:hover .custom_button_background_position{
    top: -2.5px;
    left: -2.5px;
    background-color: var(--fourthColor);
} */

/* HoverChange */
.hover_change_component{
    height: auto;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.hover_change{
    width: 200px;
    height: 200px;
    border: 5px solid var(--secondaryColor);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    z-index: 2;
}
.hover_change_background_color{
    background-color: var(--fourthColor);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.hover_change_text_hover_out{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    z-index: 2;
    cursor: pointer;
}
.hover_change_text_hover_out_text{
    text-align: center;
    cursor: pointer;
}
/* .hover_change:hover .hover_change_background_color{
    top: -2.5px;
    left: -2.5px;
    background-color: var(--thirdColor)
}
.hover_change:hover img,.hover_change:hover .mantine-Image-placeholder{
    display: none;
}
.hover_change:hover .hover_change_text_hover_out{
    display: block;
    
} */
.hover_change_title{
    width: 200px;
    margin-top: 30px;
}
.hover_change_title h3{
    text-align: center;
}

/* Collapse */
.collapse_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.collapse_content{
    border-radius: 35px 0;
    background-color: #FFFFFF;
    padding: 25px 15px;
}


/* Icon */
.icon_round{
    border: 2px solid var(--primaryColor);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* margin */
.mr_10{
    margin-right: 10px;
}
.mt_10{
    margin-top: 10px;
}
.mt_20{
    margin-top: 20px;
}
.mt_30{
    margin-top: 30px;
}
.mt_100{
    margin-top: 100px;
}
.mt_150{
    margin-top: 150px;
}
.mt_200{
    margin-top: 200px;
}

.w_100{
    width: 100%;
}

.position_relative{
    position:relative
}

/* Landing Page */
.landing_page_position{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:#FFFFFF;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
.landing_page{
    max-width: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
}
.landing_page_left{
    position: relative;
    width: 30.7432%;
    padding-top: 26.5765%;
    z-index: 2;
}
.landing_page_center{
    width: 5.2366%;
}
.landing_page_right{
    position: relative;
    width: 64.0202%;
    padding-top: 11.0547%;
    z-index: 1;
}
.landing_page_image{
    position: absolute;
    top:0 ;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}


/* Header */
.header{
    position: fixed;
    top: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.8);
    left: 0;
    right: 0;
    backdrop-filter: blur(10px);
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
}
.header_inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}
.header_logo{
    cursor: pointer;
}
.header_right_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    max-width: 600px;
    width: 100%;
}
.header_right_box_item{
    padding: 0 10px;
    height: 62px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* mobile header */
.mobile_header{
    position: fixed;
    width: 43px;
    height: 43px;
    background-color: var(--thirdColor);
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 100;
}
.mobile_header_box{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    z-index: 100;
    align-items: flex-start;
}
.mobile_header_box_menu_side{
    width: 260px;
    background-color: var(--primaryColor);
    height: 100vh;
}
.mobile_header_box_close_side{
    height: 100vh;
    background-color: rgba(255,255,255,0.8);
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.mobile_header_box_close{
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: var(--primaryColor);
    margin-right: var(--containerPaddingSide);
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* Footer */
.footer{
    background-color: var(--footerBackgroundColor);
    z-index: 90;
    position: relative;
}
.footer_inner{
    padding: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.footer_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer_row:last-child{
    margin-top: 30px;
    align-items: flex-end;
}
.footer_row_inner{
    display: flex;
    align-items: center;
}
.footer_icon_list{
    display: flex;
    width: 200px;
    justify-content: space-around;
}
.footer_row_inner_first_icon{
    width: 30px;
}
.mobile_footer_icon_list{
    display: none;
}

/* home */
.home_banner_background_color, .about_banner_background_color{
    background-image: linear-gradient(to bottom, var(--bannerBackgroundColorStart), var(--bannerBackgroundColorEnd));
}
.home_banner_background_container, .about_banner_background_container{
    position: relative;
    max-width: var(--maxContainer);
    margin: 0 auto;
    padding-top: clamp(100px, 49.8958%, 958px);
    overflow: hidden;
    background-image: url('/assets/images/bg_3.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.home_banner_background_img, .about_banner_background_img{
    position: absolute;
    
    left: 0;
    right: 0;
    bottom: 0;
}
.home_banner_content_position, .about_banner_content_position, .portfolio_banner_content_position{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.home_banner_content, .about_banner_content{
    display:flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    position: relative;
}
.home_banner_content_left_width{
    width: 55%;
    margin-right: 10%;
}
.home_banner_content_left_width > h2{
    max-width: 520px;
}
.home_banner_content_right_width{
    width: 30%;
}
.home_section_os_title_inner, .home_section_oc_title_inner{
    display: flex;
    justify-content: center;
    margin: 70px 0;
}
.home_section_os_list{
    display: flex;
    justify-content: space-between;
}
.mt_10 .custom_button{
    margin-left: 40%;
}
.home_section_eyw_overflow{
    padding-bottom: 150px;
    overflow: hidden;
}
.home_section_eyw{
    width: 100%;
    background-color: var(--thirdColor);
    border-radius: 50px;
    padding: 70px 30px 80px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 5px 5px 10px 4px rgb(0 0 0 / 75%);
    z-index: 2;
    position: relative;
}
.home_section_eyw_line_1{
    position: absolute;
    bottom: 0;
    left: -28.6363%;
    right: -28.6363%;
    /* top: 0; */
    z-index: 3;
    transform: translateY(60.5%) rotate(3deg);
}
.home_section_eyw_line_2{
    position: absolute;
    bottom: 0;
    left: -28.6363%;
    right: -28.6363%;
    /* top: 0; */
    z-index: 1;
    transform: translateY(46.5%);  
}
.home_section_eyw_line_1 > img, .home_section_eyw_line_2 > img{
    width: 100%;
}
.home_section_eyw_left{
    width: 50%
}
.home_section_eyw_right{
    margin-left: 10%;
    width: 40%;
    z-index: 4;
    position: relative;
}
.home_section_gd_overflow{
    overflow: hidden;
    padding-bottom: 100px;
}
.home_section_gd{
    width: 100%;
    background-color: var(--fourthColor);
    border-radius: 50px;
    padding: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    -webkit-box-shadow: 5px 5px 10px 4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 5px 5px 10px 4px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 10px 4px rgb(0 0 0 / 75%);
    position: relative;
    z-index: 2;
}
.home_section_gd_line{
    position: absolute;
    bottom: 0;
    left: -28.6363%;
    right: -28.6363%;
    /* top: 0; */
    z-index: 1;
    transform: translateY(19.5%);
}
.home_section_gd_line > img {
    width: 100%;
}
.home_section_gd > h1, .home_section_gd > h2{
    text-align: center;
}
.home_section_gd > h2{
    width: 70%;
    max-width: 600px;
    margin: 30px auto;
}
.home_section_gd_list{
    display:flex;
    justify-content: center;
    align-items: center;
}
.home_section_gd_item{
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 10px solid var(--secondaryColor);
    padding: 0 20px;
}
.home_section_gd_item:last-child{
    border-right: transparent;
}
.home_section_oc_overflow{
    margin-top: -100px;
}
.home_section_oc_list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
}
.home_section_oc_item{
    width: 25%;
    padding: 50px;
    position: relative;
}

.about_banner_content_left_width{
    width: 30%;
    margin-right: 5%;
    z-index: 2;
}
.about_banner_content_right_width{
    width: 55%;
    max-width: 520px;
    z-index: 2;
}
.about_section_eyw_line{
    position: absolute;
    bottom: 0;
    left: -28.6363%;
    right: -28.6363%;
    /* top: 0; */
    z-index: 1;
    transform: translateY(18.5%) translateX(10.5%);
}

.about_section_eyw_line1{
    position: absolute;
    bottom: 0;
    left: -28.6363%;
    right: -28.6363%;
    /* top: 0; */
    z-index: 1;
    transform: translateY(18.5%) translateX(10.5%);
}

.about_section_eyw_line1 > img{
    width: 100%;
}
.about_section_eyw_line > img{
    width: 100%;
}
.about_section > div > h2{
    text-align: center;
    max-width: 760px;
    margin: 0 auto 0;
}
.about_section_list{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}
.about_section_item{
    width: 20%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.about_section_item > h1 {
    font-size: 24px;
}

.service_banner_content{
    display:flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    position: relative;
}

.service_banner_content_left_width{
    width: 40%;
    margin-right: 5%;
    z-index: 2;
}
.service_banner_content_right_width{
    width: 40%;
    display: flex;
    flex-direction: column;
}
.service_section_list{
    display: grid;
    justify-items: center;
    grid-template-columns: auto auto auto;
    grid-row-gap: 50px;
}
.service_section_contact_us_overflow{
    overflow: hidden;
}
.service_section_contact_us{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    position: relative;
}
.service_section_contact_us_line{
    position: absolute;
    bottom: 0;
    left: -28.6363%;
    right: -28.6363%;
    /* top: 0; */
    z-index: 1;
    transform: translateY(44.5%) rotate(3deg) translateX(5%);
}
.service_section_contact_us_line > div > img{
    width: 100%;
}
.service_section_contact_us_left{
    width: 30%;
    margin-right: 10%;
}
.service_section_contact_us_right{
    width: 40%;
    z-index: 2;
}

.portfolio_banner_background_container{
    position: relative;
    max-width: var(--maxContainer);
    margin: 0 auto;
    padding-top: clamp(100px, 49.8958%, 958px);
    overflow: hidden;
    background-image: url('/assets/images/bg_3.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.portfolio_banner_content{
    display:flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    position: relative;
}
.portfolio_banner_content_left_width{
    width: 87%;
    max-width: 520px;
    margin-right: 5%
}
.portfolio_banner_content_right_width{
    width: 35%
}
.portfolio_banner_content_right_img{
    transform: matrix(0.99, 0.14, -0.14, 0.99, 0, 0);
}
.portfolio_section_list{
    display: grid;
    grid-template-columns: auto auto;
    gap: 50px 20px;
    margin: 50px 0;
    z-index: 3;
    position: relative;
}
.portfolio_section_list_inner{
    position:relative;
    overflow: hidden;
}
.portfolio_section_list_line_1{
    position: absolute;
    /* bottom: 0; */
    left: -28.6363%;
    right: -28.6363%;
    top: 0;
    z-index: 1;
    transform: translateY(70%) rotate(-17deg);
}
.portfolio_section_list_line_2{
    position: absolute;
    /* bottom: 0; */
    left: -28.6363%;
    right: -28.6363%;
    top: 0;
    z-index: 1;
    transform: translateY(4%) rotate(-18deg);
}
.portfolio_section_list_line_3{
    position: absolute;
    bottom: 0;
    left: -28.6363%;
    right: -28.6363%;
    /* top: 0; */
    z-index: 1;
    transform: translateY(20%);
}
.portfolio_section_list_line_4{
    position: absolute;
    bottom: 0;
    left: -28.6363%;
    right: -28.6363%;
    /* top: 0; */
    z-index: 1;
    transform: translateY(30%);
}
.portfolio_section_list_line_1 > img, .portfolio_section_list_line_2 > img, .portfolio_section_list_line_3 > img, .portfolio_section_list_line_4 > img {
    width: 100%;
}
.portfolio_section_item{
    background-color: #FFFFFF;
    -webkit-box-shadow: 2px 2px 15px 1px rgb(0 0 0 / 25%);
    -moz-box-shadow: 2px 2px 15px 1px rgba(0,0,0,0.25);
    box-shadow: 2px 2px 15px 1px rgb(0 0 0 / 25%);
    border-radius: 30px;
}
.portfolio_section_item_inner{
    padding: 30px 10px;
}
.contact_banner_content_left_width{
    width: 40%;
    margin-right: 5%;
    z-index: 2;
}
.contact_banner_content_right_width{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact_section{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    margin: 50px 0;
}
.contact_section_left_container{
   display: flex;
   width: 350px;
   margin-right: 20px;
   flex-direction: column;
}
.contact_section_left_content{
    width: 100%;
    background-color: var(--thirdColor);
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 10px;
}
.contact_section_left_content_paragraph{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.contact_section_left_content_paragraph svg{
    width: 15px;
    margin-right: 5px;
}
.contact_section_left_content_paragraph p{
    font-size: 12px;
    line-height: 15px;
    width: 80%;
}
.contact_section_left_content_paragraph p a{
    text-decoration: none;
}

.contact_section_left_icon_list{
    display: flex;
    justify-content: flex-start;
}
.contact_section_right_content{
    flex: 1;
}

/* Adjust the size of the image inside the Swiper slide */
.swiper-slide img {
    max-width: 70%;
    max-height: 200px; /* Adjust this value as needed */
    display: block;
    margin: 0 auto; /* Center the image horizontally */
    margin-bottom: 50px;
}

.phone-link {
    color: inherit; /* to inherit the color from the parent element */
    text-decoration: none; /* to remove the underline */
}

.phone-link:hover {
    text-decoration: underline; /* optional: underline on hover for better UX */
}
